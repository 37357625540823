import React from 'react';
import modules from '../../services/modules';
import i18next from 'i18next';
import { updateSecurityDetails } from '../reducers/securityCheckSlice';
import { PlanTypes } from '../../config/plan-types';

import {
	CHECKING_SECURITY_START,
	CHECKING_SECURITY_SUCCESS,
	CHECKING_SECURITY_FAIL,

	GET_PACKAGES_LIST_START,
	GET_PACKAGES_LIST_SUCCESS,
	GET_PACKAGES_LIST_FAIL,

	GET_PAYMENT_METHODS_START,
	GET_PAYMENT_METHODS_SUCCESS,
	GET_PAYMENT_METHODS_FAIL,

	CREATE_SAVED_PAYMENT_START,
	CREATE_SAVED_PAYMENT_FINISH,

	CREATE_PAYMENT_START,
	CREATE_PAYMENT_SUCCESS,
	CREATE_PAYMENT_FAIL,

	CREATE_IDEAL_PAYMENT_START,
	CREATE_IDEAL_PAYMENT_SUCCESS,
	CREATE_IDEAL_PAYMENT_FAIL,

	CREATE_SEPA_PAYMENT_START,
	CREATE_SEPA_PAYMENT_SUCCESS,
	CREATE_SEPA_PAYMENT_FAIL,

	CREATE_COINBASE_PAYMENT_START,
	CREATE_COINBASE_PAYMENT_SUCCESS,
	CREATE_COINBASE_PAYMENT_FAIL,

	CREATE_COINBILL_PAYMENT_START,
	CREATE_COINBILL_PAYMENT_SUCCESS,
	CREATE_COINBILL_PAYMENT_FAIL,

	CREATE_PAYMENTWALL_PAYMENT_START,
	CREATE_PAYMENTWALL_PAYMENT_SUCCESS,
	CREATE_PAYMENTWALL_PAYMENT_FAIL,

	CREATE_PAYPAL_PAYMENT_START,
	CREATE_PAYPAL_PAYMENT_SUCCESS,
	CREATE_PAYPAL_PAYMENT_FAIL,

	CREATE_FREEMIUM_START,
	CREATE_FREEMIUM_SUCCESS,
	CREATE_FREEMIUM_FAIL,

	SET_SELECTED_PACKAGE,

	GET_COUPON_START,
	GET_COUPON_SUCCESS,
	GET_COUPON_FAIL,

	GET_COUPON_MONTHLY_START,
	GET_COUPON_MONTHLY_SUCCESS,
	GET_COUPON_MONTHLY_FAIL,

	GET_COUPON_YEARLY_2_START,
	GET_COUPON_YEARLY_2_SUCCESS,
	GET_COUPON_YEARLY_2_FAIL,

	GET_COUPON_BY_CODE_START,
	GET_COUPON_BY_CODE_SUCCESS,
	GET_COUPON_BY_CODE_FAIL,
	RESET_COUPON_BY_CODE,

	GET_SERVERS_DETAILS,

	REACTIVATE_FREEMIUM_START,
	REACTIVATE_FREEMIUM_SUCCESS,
	REACTIVATE_FREEMIUM_FAIL,

	USER_AUTH_INFO_START,
	USER_AUTH_INFO_FINISH,

	GET_IDEAL_BANK_LIST,

	UPDATE_AVAILABLE_CURRENCIES,

	UPDATE_QUERY_PARAMS,
	CHECK_FREE_RECAPTCHA,
	LOAD_UNCONDITIONALLY_RECAPTCHA,
	UPDATE_SENTRY_PROPS
} from './actionTypes';

const { API, utils, toast } = modules;
const {REACT_APP_CREDIT_CARD, REACT_APP_PAYPAL_FLEXIBLE, REACT_APP_SEPA_DIRECT_DEBIT, REACT_APP_IDEAL, REACT_APP_COINBASE, REACT_APP_PAYMENTWALL, REACT_APP_COINBILL} = process.env;
const _paymentErrorMessage = 'There was a problem with your payment method. Please select a different payment method and try again.';

/* Check Security start */
const checkSecurityStart = () => {
	return {
		type: CHECKING_SECURITY_START
	};
};

const checkSecuritySuccess = securityDetails => {
	return {
		type: CHECKING_SECURITY_SUCCESS,
		securityDetails
	};
};

const checkSecurityFail = () => {
	return {
		type: CHECKING_SECURITY_FAIL
	};
};

export const checkSecurity = () => {
	return (dispatch, getState) => {
		dispatch(checkSecurityStart());
		return API.checkSecurity()
			.then(resp => {
				if(resp?.data?.status === 'OK') {
					const _data = resp?.data?.data || {};
					_data.current_currency = 'USD';
					dispatch(checkSecuritySuccess(_data));
					return _data;
				} else {
					dispatch(checkSecurityFail());
					return getState().home.securityDetails;
				}
			})
			.catch(error => {
				if(error?.response?.data?.status === 'Forbidden') {
					utils.handleError('sch - Forbidden', error);
					const _securityDetails = getState().home.securityDetails;
					dispatch(checkSecuritySuccess({..._securityDetails, is_blocked: true}));
					return {..._securityDetails, is_blocked: true};
				} else {
					utils.handleError('sch', error);
					dispatch(checkSecurityFail());
					return getState().home.securityDetails;
				}
			});
	};
};
/* Check Security end */

/* packages list start */
const getPackagesListStart = () => {
	return {
		type: GET_PACKAGES_LIST_START
	};
};

const getPackagesListSuccess = packagesList => {
	return {
		type: GET_PACKAGES_LIST_SUCCESS,
		packagesList
	};
};

const getPackagesListFail = () => {
	return {
		type: GET_PACKAGES_LIST_FAIL
	};
};

export const getPackagesList = () => {
	return (dispatch) => {

		dispatch(getPackagesListStart());

		return API.getPackagesList()
			.then(response => {
				const packagesList = Array.isArray(response.data) ? response.data : [];
				dispatch(getPackagesListSuccess(packagesList));
				return packagesList;
			})
			.catch(error => {
				dispatch(getPackagesListFail());
				utils.handleError('pac', error);
				return [];
			});
	};
};
/* packages list end */

/* payment methods start */
const getPaymentMethodsStart = () => {
	return {
		type: GET_PAYMENT_METHODS_START
	};
};

const getPaymentMethodsSuccess = (paymentMethods, allowedCountries) => {
	return {
		type: GET_PAYMENT_METHODS_SUCCESS,
		paymentMethods,
		allowedCountries
	};
};

const getPaymentMethodsFail = () => {
	return {
		type: GET_PAYMENT_METHODS_FAIL
	};
};

export const getPaymentMethods = (dataToSend) => {
	return (dispatch) => {

		dispatch(getPaymentMethodsStart());

		API.getPaymentMethods(dataToSend)
			.then(response => {
				const _resp = response.data || {};
				const _pObj = _resp.payment_methods || {};
				const _allowedCountries = _resp.allowed_countries || [];
				let paymentMethods = Object.keys(_pObj).map((key) => {
					switch(_pObj[key].name) {
					case REACT_APP_CREDIT_CARD:
						_pObj[key].displayName = 'Credit Card';
						return _pObj[key];
					case REACT_APP_PAYPAL_FLEXIBLE:
						_pObj[key].displayName = 'PayPal';
						return _pObj[key];
					case REACT_APP_COINBILL:
						_pObj[key].displayName = 'Cryptocurrency';
						return _pObj[key];
					default:
						return _pObj[key];
					}
				});

				paymentMethods.sort((a, b) => {return ((+a.priority || 0) - (+b.priority || 0));});

				paymentMethods = paymentMethods.map((p) => {
					if(p.flexibleId) {
						p = {
							...p,
							...p.billingMethodObject,
							metaData: p.metaData,
							name: 'savedPayment',
							displayName: 'Saved Payment Method',
							billingMethodId: p.flexibleId
						};
					}
					return p;
				});

				const _firstMethod =  paymentMethods.shift();
				const _paymentMethods = paymentMethods.filter(p => [REACT_APP_CREDIT_CARD, REACT_APP_PAYPAL_FLEXIBLE, REACT_APP_SEPA_DIRECT_DEBIT, REACT_APP_IDEAL, REACT_APP_COINBASE, REACT_APP_COINBILL].indexOf(p.name) > -1);
				const _paymentMethodsOthers = paymentMethods.filter(p => (p.name).indexOf(REACT_APP_PAYMENTWALL) > -1);
				
				if(_firstMethod.name.indexOf(REACT_APP_PAYMENTWALL) > -1) {
					_firstMethod.paymentWallMethods = [{..._firstMethod}];
				}

				_paymentMethods.unshift(_firstMethod);

				if(_paymentMethodsOthers.length) {
					_paymentMethods.push({
						name: 'paymentwall',
						displayName: 'Other',
						paymentWallMethods: _paymentMethodsOthers
					});
				}

				dispatch(getPaymentMethodsSuccess(_paymentMethods.filter(p => !(!dataToSend.show_sdd_method && p.name === REACT_APP_SEPA_DIRECT_DEBIT)), _allowedCountries));

				if(!_paymentMethods.length) {
					toast.warn(i18next.t('No Payment Methods Available'), {
						position: toast.POSITION.TOP_RIGHT
					});
				}
			})
			.catch(error => {
				dispatch(getPaymentMethodsFail());
				console.log(error);
				
				utils.handleError('gpm', error);
			});
	};
};
/* payment methods end */

/* process payment error response */
const processErrorResponse = (type, error, dispatch, getState, extraData) => {
	const _securityDetails = getState().securityCheck.securityDetails;
	const _data =  error?.response?.data?.data || {};

	if(_data.is_proxy_detected) {
		dispatch(updateSecurityDetails({..._securityDetails, is_proxy_detected: _data.is_proxy_detected}));
	} else {
		utils.handleError(type, error, <span dangerouslySetInnerHTML={{__html: i18next.t(_data?.is_sentry_addon_exists && error?.response?.data?.error || _data?.reason || _paymentErrorMessage)}} />, extraData);
		if(error?.response?.status !== 403) {
			utils.captureSentryMessage({
				...extraData,
				message: `Failed Create Payment - ${type} - ${extraData.country}`,
				messageType: 'info',
				errorType: 'failed_create_payment',
				errorMessage: error?.message, 
				endpointCode: type
			});
		}
	}
};

/* captchaIsProcessing */
export const captchaIsProcessing = (paymentType, loading, error, errorName) => {
	return (dispatch) => {
		if(!loading) {
			utils.handleError('captcha', error || new Error(errorName), i18next.t('Something went wrong, please try again'));
		}
		switch(paymentType) {
		case REACT_APP_CREDIT_CARD:
			return dispatch(loading ? createPaymentStart() : createPaymentFail());
		case REACT_APP_IDEAL:
			return dispatch(loading ? createIdealPaymentStart() : createIdealPaymentFail());
		case REACT_APP_SEPA_DIRECT_DEBIT:
			return dispatch(loading ? createSepaPaymentStart() : createSepaPaymentFail());
		case REACT_APP_COINBASE:
			return dispatch(loading ? createCoinbasePaymentStart() : createCoinbasePaymentFail());
		case REACT_APP_PAYMENTWALL:
			return dispatch(loading ? createPaymentWallStart() : createPaymentWallFail());
		case REACT_APP_PAYPAL_FLEXIBLE:
			return dispatch(loading ? createPaypalPaymentStart() : createPaypalPaymentFail());
		case 'FREEMIUM':
			return dispatch(loading ? createFreemiumStart() : createFreemiumFail());
		default:
			return null;
		}
		
	};
};

/* create saved payment start */
const createSavedPaymentStart = () => {
	return {
		type: CREATE_SAVED_PAYMENT_START
	};
};

const createSavedPaymentFinish = () => {
	return {
		type: CREATE_SAVED_PAYMENT_FINISH
	};
};

export const createSavedPayment = dataToSend => {
	return async (dispatch, getState) => {
		dispatch(createSavedPaymentStart());

		try {
			const response = await API.createSavedPayment(dataToSend);
			if (!response?.data?.data?.redirect_url) {
				dispatch(createSavedPaymentFinish());
				toast.error(i18next.t(_paymentErrorMessage), {
					position: toast.POSITION.TOP_RIGHT
				});
			}
			return response.data;
		} catch (error) {
			dispatch(createSavedPaymentFinish());
			processErrorResponse('csp', error, dispatch, getState, dataToSend);
			return error.response;
		}
	};
};
/* create saved payment end */

/* create payment start */
const createPaymentStart = () => {
	return {
		type: CREATE_PAYMENT_START
	};
};

const createPaymentSuccess = payment => {
	return {
		type: CREATE_PAYMENT_SUCCESS,
		payment
	};
};

const createPaymentFail = () => {
	return {
		type: CREATE_PAYMENT_FAIL
	};
};

export const processTokenize = () => {
	return (dispatch) => {
		dispatch(createPaymentStart());
	};
};

export const createPayment = dataToSend => {
	return async (dispatch, getState) => {
		dispatch(createPaymentStart());

		try {
			const response = await API.createPayment(dataToSend);
			if (response?.data?.status === 'OK') {
				dispatch(createPaymentSuccess(response.data));
			} else {
				toast.error(i18next.t(_paymentErrorMessage), {
					position: toast.POSITION.TOP_RIGHT
				});
			}
			return response.data;
		} catch (error) {
			dispatch(createPaymentFail());
			processErrorResponse('cp', error, dispatch, getState, dataToSend);
			return error.response;
		}
	};
};
/* create payment end */

/* create iDeal payment start */
const createIdealPaymentStart = () => {
	return {
		type: CREATE_IDEAL_PAYMENT_START
	};
};

const createIdealPaymentSuccess = payment => {
	return {
		type: CREATE_IDEAL_PAYMENT_SUCCESS,
		payment
	};
};

const createIdealPaymentFail = () => {
	return {
		type: CREATE_IDEAL_PAYMENT_FAIL
	};
};

export const createIdealPayment = dataToSend => {
	return async (dispatch,  getState) => {
		dispatch(createIdealPaymentStart());

		try {
			const response = await API.createIdealPayment(dataToSend);
			if (response?.data?.status === 'OK') {
				dispatch(createIdealPaymentSuccess(response.data));
			} else {
				toast.error(i18next.t(_paymentErrorMessage), {
					position: toast.POSITION.TOP_RIGHT
				});
			}
			return response.data;
		} catch (error) {
			dispatch(createIdealPaymentFail());
			processErrorResponse('cip', error, dispatch, getState, dataToSend);
			return error.response;
		}
	};
};
/* create iDeal payment end */

/* create Sepa payment start */
const createSepaPaymentStart = () => {
	return {
		type: CREATE_SEPA_PAYMENT_START
	};
};

const createSepaPaymentSuccess = payment => {
	return {
		type: CREATE_SEPA_PAYMENT_SUCCESS,
		payment
	};
};

const createSepaPaymentFail = () => {
	return {
		type: CREATE_SEPA_PAYMENT_FAIL
	};
};

export const createSepaPayment = dataToSend => {
	return async (dispatch, getState) => {
		dispatch(createSepaPaymentStart());

		try {
			const response = await API.createSepaPayment(dataToSend);
			if (response?.data?.status === 'OK') {
				dispatch(createSepaPaymentSuccess(response.data));
			} else {
				toast.error(i18next.t(_paymentErrorMessage), {
					position: toast.POSITION.TOP_RIGHT
				});
			}
			return response.data;
		} catch (error) {
			dispatch(createSepaPaymentFail());
			processErrorResponse('csp', error, dispatch, getState, dataToSend);
			return error.response;
		}
	};
};
/* create Sepa payment end */

/* create Coinbase payment start */
const createCoinbasePaymentStart = () => {
	return {
		type: CREATE_COINBASE_PAYMENT_START
	};
};

const createCoinbasePaymentSuccess = payment => {
	return {
		type: CREATE_COINBASE_PAYMENT_SUCCESS,
		payment
	};
};

const createCoinbasePaymentFail = () => {
	return {
		type: CREATE_COINBASE_PAYMENT_FAIL
	};
};

export const createCoinbasePayment = dataToSend => {
	return async (dispatch, getState) => {
		dispatch(createCoinbasePaymentStart());

		try {
			const response = await API.createCoinbasePayment(dataToSend);
			if (response?.data?.status === 'OK') {
				dispatch(createCoinbasePaymentSuccess(response.data));
			} else {
				toast.error(i18next.t(_paymentErrorMessage), {
					position: toast.POSITION.TOP_RIGHT
				});
			}
			return response.data;
		} catch (error) {
			dispatch(createCoinbasePaymentFail());
			processErrorResponse('ccp', error, dispatch, getState, dataToSend);
			return error.response;
		}
	};
};
/* create Coinbase payment end */

/* create Coinbill payment start */
const createCoinbillPaymentStart = () => {
	return {
		type: CREATE_COINBILL_PAYMENT_START
	};
};

const createCoinbillPaymentSuccess = payment => {
	return {
		type: CREATE_COINBILL_PAYMENT_SUCCESS,
		payment
	};
};

const createCoinbillPaymentFail = () => {
	return {
		type: CREATE_COINBILL_PAYMENT_FAIL
	};
};

export const createCoinbillPayment = dataToSend => {
	return async (dispatch, getState) => {
		dispatch(createCoinbillPaymentStart());

		try {
			const response = await API.createCoinbillPayment(dataToSend);
			if (response?.data?.status === 'OK') {
				dispatch(createCoinbillPaymentSuccess(response.data));
			} else {
				toast.error(i18next.t(_paymentErrorMessage), {
					position: toast.POSITION.TOP_RIGHT
				});
			}
			return response.data;
		} catch (error) {
			dispatch(createCoinbillPaymentFail());
			processErrorResponse('ccp', error, dispatch, getState, dataToSend);
			return error.response;
		}
	};
};
/* create Coinbill payment end */

/* create PaymentWall payment start */
const createPaymentWallStart = () => {
	return {
		type: CREATE_PAYMENTWALL_PAYMENT_START
	};
};

const createPaymentWallSuccess = payment => {
	return {
		type: CREATE_PAYMENTWALL_PAYMENT_SUCCESS,
		payment
	};
};

const createPaymentWallFail = () => {
	return {
		type: CREATE_PAYMENTWALL_PAYMENT_FAIL
	};
};

export const createPaymentWall = dataToSend => {
	return async (dispatch, getState) => {
		dispatch(createPaymentWallStart());

		try {
			const response = await API.createPaymentWall(dataToSend);
			if (response.data && response.data.status === 'OK') {
				dispatch(createPaymentWallSuccess(response.data));
			} else {
				toast.error(i18next.t(_paymentErrorMessage), {
					position: toast.POSITION.TOP_RIGHT
				});
			}
			return response.data;
		} catch (error) {
			dispatch(createPaymentWallFail());
			processErrorResponse('cpw', error, dispatch, getState, dataToSend);
			return error.response;
		}
	};
};
/* create PaymentWall payment end */

/* create Paypal payment start */
const createPaypalPaymentStart = () => {
	return {
		type: CREATE_PAYPAL_PAYMENT_START
	};
};

const createPaypalPaymentSuccess = token => {
	return {
		type: CREATE_PAYPAL_PAYMENT_SUCCESS,
		token
	};
};

const createPaypalPaymentFail = () => {
	return {
		type: CREATE_PAYPAL_PAYMENT_FAIL
	};
};

export const createPaypalPayment = dataToSend => {
	return async (dispatch, getState) => {
		dispatch(createPaypalPaymentStart());

		try {
			const response = await API.createPaypalPayment(dataToSend);
			if (response.data && response.data.status === 'OK') {
				dispatch(createPaypalPaymentSuccess(response.data));
				toast.success(i18next.t('You will be redirected to Paypal page'), {
					position: toast.POSITION.TOP_RIGHT
				});
			} else {
				toast.error(i18next.t(_paymentErrorMessage), {
					position: toast.POSITION.TOP_RIGHT
				});
			}
			return response.data;
		} catch (error) {
			dispatch(createPaypalPaymentFail());
			processErrorResponse('cpp', error, dispatch, getState, dataToSend);
			return error.response;
		}
	};
};
/* create Paypal payment end */

/* create freemium start */
const createFreemiumStart = () => {
	return {
		type: CREATE_FREEMIUM_START
	};
};

const createFreemiumSuccess = createFreemiumState => {
	return {
		type: CREATE_FREEMIUM_SUCCESS,
		createFreemiumState
	};
};

const createFreemiumFail = () => {
	return {
		type: CREATE_FREEMIUM_FAIL
	};
};

export const createFreemium = dataToSend => {
	return (dispatch, getState) => {
		dispatch(createFreemiumStart());
		const _createFreemiumState = {success: false, email: dataToSend.email};
		const _securityDetails = getState().securityCheck.securityDetails;

		return API.createFreemium(dataToSend)
			.then(response => {
				if(response.data && response.data.success) {
					_createFreemiumState.success = true;
					dispatch(createFreemiumSuccess(_createFreemiumState));
				} else {
					toast.error(i18next.t('Something went wrong, please try again'), {
						position: toast.POSITION.TOP_RIGHT
					});
				}
				return _createFreemiumState;
			})
			.catch((err) => {
				const _data =  err?.response?.data?.data || {};

				dispatch(createFreemiumFail());
				if(_data.is_proxy_detected) {
					dispatch(updateSecurityDetails({..._securityDetails, is_proxy_detected: _data.is_proxy_detected}));
				} else {
					if(err?.response?.data?.status === 'Bad Request') {
						toast.error(i18next.t('This email is already exist'), {
							position: toast.POSITION.TOP_RIGHT
						});
					} else {
						if(err?.response?.data?.reason === 'no data or g-recaptcha-response in data.keys') {
							dispatch(loadUnconditionallyCaptchaKey());
						} else {
							utils.handleError('cf', err);
						}
						if(err?.response?.status !== 403) {
							utils.captureSentryMessage({
								...dataToSend,
								message: `Failed Create Freemium - ${dataToSend.country}`,
								messageType: 'info',
								errorType: 'failed_create_freemium',
								errorMessage: err?.message
							});
						}
					}
				}

				return _createFreemiumState;
			});
	};
};
/* create freemium end */

/* reactivate freemium start */
const reactivateFreemiumStart = () => {
	return {
		type: REACTIVATE_FREEMIUM_START
	};
};

const reactivateFreemiumSuccess = () => {
	return {
		type: REACTIVATE_FREEMIUM_SUCCESS
	};
};

const reactivateFreemiumFail = () => {
	return {
		type: REACTIVATE_FREEMIUM_FAIL
	};
};

export const reactivateFreemium = dataToSend => {
	return (dispatch, getState) => {
		const _securityDetails = getState().securityCheck.securityDetails;

		dispatch(reactivateFreemiumStart());

		return API.reactivateFreemium(dataToSend)
			.then(response => {
				if(response.data && response.data.status === 'OK') {
					dispatch(reactivateFreemiumSuccess());
				} else {
					toast.error(i18next.t('Something went wrong, please come back later'), {
						position: toast.POSITION.TOP_RIGHT
					});
				}
				return response.data;
			})
			.catch(error => {
				const _data =  (error.response && error.response.data && error.response.data.data) || {};

				dispatch(reactivateFreemiumFail());
				if(_data.is_proxy_detected) {
					dispatch(updateSecurityDetails({..._securityDetails, is_proxy_detected: _data.is_proxy_detected}));
				} else {
					utils.handleError('rf', error);
				}

				return error.response && error.response.data || {error: true};
			});
	};
};
/* reactivate freemium end */

/* Check Email start */
export const checkEmail = email => {
	return () => {

		return API.checkEmail(email)
			.then(response => {
				return response.data;
			})
			.catch( (err) => {
				const _data =  (err.response && err.response.data) || {};
				return {error: (err.message !== 'canceledCheckEmailRequest'), data: _data};
			});
	};
};
/* Check Email end */

/* Check Order start */
export const checkOrder = order => {
	return () => {
		return API.checkOrder(order)
			.then(response => {
				return response.data;
			})
			.catch( (err) => {
				return err.response.data;
			});
	};
};
/* Check Order end */

/* User Auth start */
export const userAuth = data => {
	return async () => {
		try {
			const response = await API.userAuth(data);
			return response.data || {};
		} catch (err) {
			const _errResp = (err.response && err.response.data) || {};
			if (_errResp.code === 302 && _errResp.data && _errResp.data.redirect_url) {
				//toast.warn('You will be redirected to admin page', {
				//position: toast.POSITION.TOP_RIGHT
				//});
			} else if (_errResp.status !== 'Bad Request') {
				utils.handleError('pa', err);
			}
			return _errResp;
		}
	};
};
/* User Auth end */

/* Get User Auth Info start */
const getUserAuthInfoStart = () => {
	return {
		type: USER_AUTH_INFO_START
	};
};

const getUserAuthInfoFinish = () => {
	return {
		type: USER_AUTH_INFO_FINISH
	};
};

export const getUserAuthInfo = data => {
	return async (dispatch) => {
		dispatch(getUserAuthInfoStart());
		try {
			const response = await API.userAuth(data);
			dispatch(getUserAuthInfoFinish());
			return response.data || {};
		} catch (err) {
			dispatch(getUserAuthInfoFinish());
			toast.error(i18next.t('Authentication failed. Please login.'), {
				position: toast.POSITION.TOP_RIGHT
			});
			return (err.response && err.response.data) || {};
		}
	};
};
/* Get User Auth Info end */

/* Set Selected Package start */
export const setSP = selectedPackage => {
	return {
		type: SET_SELECTED_PACKAGE,
		selectedPackage
	};
};

export const setSelectedPackage = selectedPackage => {
	return (dispatch) => {
		dispatch(setSP(selectedPackage));

	};
};
/* Set Selected Package end */

/* Change Currency */
export const changeCurrency = (current_currency) => {
	return (dispatch, getState) => {
		const {securityDetails, availableCurrencies} = getState().home;
		const newSecurityDetails = {...securityDetails, current_currency};
		dispatch(updateSecurityDetails(newSecurityDetails));
		if(availableCurrencies.indexOf(current_currency) === -1) {
			dispatch(updateAvailableCurrencies([current_currency, ...availableCurrencies]));
		}
	};
};
/* Change Currency end */

/* Change Country */
export const changeCountry = (iso_code) => {
	return (dispatch, getState) => {
		const {securityDetails} = getState().home;
		const newSecurityDetails = {...securityDetails, iso_code};
		dispatch(updateSecurityDetails(newSecurityDetails));
	};
};
/* Change Country end */

/* Get Currency start */
const updateAvailableCurrencies= availableCurrencies => {
	return {
		type: UPDATE_AVAILABLE_CURRENCIES,
		availableCurrencies
	};
};

export const getCurrency = (countryCode, savedCurrency) => {
	return (dispatch, getState) => {
		return API.getCurrency(countryCode)
			.then(response => {
				let available_currencies = response.data?.data?.available_currencies || [];
				if(savedCurrency && available_currencies.indexOf(savedCurrency) === -1) {
					available_currencies.unshift(savedCurrency);
				}
				dispatch(updateAvailableCurrencies(available_currencies));
				return available_currencies;
			})
			.catch(() => {
				return getState().home.availableCurrencies;
			});
	};
};
/* Get Currency end */

/* Get IP start */
export const getIP = (selectedPaymentMethod) => {
	return async (dispatch) => {
		switch(selectedPaymentMethod) {
		case REACT_APP_CREDIT_CARD:
			dispatch(createPaymentStart());
			break;
		case REACT_APP_IDEAL:
			dispatch(createIdealPaymentStart());
			break;
		case REACT_APP_SEPA_DIRECT_DEBIT:
			dispatch(createSepaPaymentStart());
			break;
		case REACT_APP_COINBASE:
			dispatch(createCoinbasePaymentStart());
			break;
		case REACT_APP_PAYMENTWALL:
			dispatch(createPaymentWallStart());
			break;
		default:
			null;
		}
		try {
			const response = await API.getIp();
			return response.data;
		} catch (err) {
			return err.response && err.response.data || {};
		}
	};
};
/* Get IP end */

/* Send Abandon Email start */
export const sendAbandonEmailRequest = data => {
	return () => {
		return API.sendAbandonEmailRequest(data)
			.then(response => {
				return response.data;
			})
			.catch((err) => {
				return err.response && err.response.data || {};
			});
	};
};
/* Send Abandon Email end */

/* Get Coupon start */
const getCouponStart = () => {
	return {
		type: GET_COUPON_START
	};
};

const getCouponSuccess = coupon => {
	return {
		type: GET_COUPON_SUCCESS,
		coupon
	};
};

const getCouponFail = () => {
	return {
		type: GET_COUPON_FAIL,
		coupon: {}
	};
};

export const getCoupon = (dataToSend) => {
	return (dispatch) => {

		if(!dataToSend.disableLoading) {
			dispatch(getCouponStart());
		}

		return API.getCoupon(dataToSend)
			.then(response => {
				const _coupon = response?.data?.data;
				if(_coupon) {
					if(_coupon?.validPlans.indexOf(PlanTypes.YEARLYS) > -1) {
						_coupon?.validPlans.unshift(PlanTypes.YEARLY);
					}
					dispatch(getCouponSuccess(_coupon));
				} else {
					dispatch(getCouponFail());
				}
				return _coupon;
			})
			.catch(() => {
				dispatch(getCouponFail());
				return {};
			});
	};
};
/* Get Coupon end */

/* Get CouponMonthly start */
const getCouponMonthlyStart = () => {
	return {
		type: GET_COUPON_MONTHLY_START
	};
};

const getCouponMonthlySuccess = couponMonthly => {
	return {
		type: GET_COUPON_MONTHLY_SUCCESS,
		couponMonthly
	};
};

const getCouponMonthlyFail = () => {
	return {
		type: GET_COUPON_MONTHLY_FAIL
	};
};

export const getCouponMonthly = (code, package_id, addons) => {
	return (dispatch) => {
		dispatch(getCouponMonthlyStart());

		return API.getCoupon({code, package_id, addons})
			.then(response => {
				if(response?.data?.data) {
					dispatch(getCouponMonthlySuccess(response?.data?.data));
					return response?.data?.data;
				} else {
					dispatch(getCouponMonthlyFail());
					return {};
				}
			})
			.catch(() => {
				dispatch(getCouponMonthlyFail());
				return {};
			});
	};
};
/* Get CouponMonthly end */

/* Get CouponYearly2 start */
const getCouponYearly2Start = () => {
	return {
		type: GET_COUPON_YEARLY_2_START
	};
};

const getCouponYearly2Success = couponYearly2 => {
	return {
		type: GET_COUPON_YEARLY_2_SUCCESS,
		couponYearly2
	};
};

const getCouponYearly2Fail = () => {
	return {
		type: GET_COUPON_YEARLY_2_FAIL
	};
};

export const getCouponYearly2 = (code, package_id, addons) => {
	return (dispatch) => {
		dispatch(getCouponYearly2Start());

		return API.getCoupon({code, package_id, addons})
			.then(response => {
				if(response?.data?.data) {
					dispatch(getCouponYearly2Success(response?.data?.data));
					return response?.data?.data;
				} else {
					dispatch(getCouponYearly2Fail());
					return {};
				}
			})
			.catch(() => {
				dispatch(getCouponYearly2Fail());
				return {};
			});
	};
};
/* Get CouponYearly2 end */

/* Get Coupon By Code start */
const getCouponByCodeStart = () => {
	return {
		type: GET_COUPON_BY_CODE_START
	};
};

const getCouponByCodeSuccess = couponByCode => {
	return {
		type: GET_COUPON_BY_CODE_SUCCESS,
		couponByCode
	};
};

const getCouponByCodeFail = couponByCode => {
	return {
		type: GET_COUPON_BY_CODE_FAIL,
		couponByCode
	};
};

export const getCouponByCode = (code, package_id, addons, email) => {

	return (dispatch) => {
		const _errorResponse = {error: true, code};
		dispatch(getCouponByCodeStart());

		return API.getCoupon({code, package_id, addons, email: email || null})
			.then(response => {
				if(response?.data?.data?.code) {
					const _coupon = response?.data?.data;
					if(_coupon?.validPlans.indexOf(PlanTypes.YEARLYS) > -1) {
						_coupon?.validPlans.unshift(PlanTypes.YEARLY);
						_coupon.sentryPeriod = _coupon.code.split('S120x')[1];
						_coupon.includeSentry = true;
					}
					if(_coupon?.validPlans.indexOf(PlanTypes.YEARLY2S) > -1) {
						_coupon?.validPlans.unshift(PlanTypes.YEARLY2);
						_coupon.sentryPeriod = _coupon.code.split('S240x')[1];
						_coupon.includeSentry = true;
					}
					dispatch(getCouponByCodeSuccess({..._coupon, success: true}));
					return {..._coupon, success: true};
				} else {
					_errorResponse.message = response?.data?.response || '';
					dispatch(getCouponByCodeFail(_errorResponse));
					return _errorResponse;
				}
			})
			.catch(() => {
				dispatch(getCouponByCodeFail(_errorResponse));
				return _errorResponse;
			});
	};
};
/* Get Coupon By Code end */

/* Reset Coupon By Code */
const resetCoupon = (prevCode) => {
	return {
		type: RESET_COUPON_BY_CODE,
		couponByCode: {prevCode}
	};
};

export const resetCouponByCode = (prevCode) => {
	return (dispatch) => {
		dispatch(resetCoupon(prevCode));
	};
};
/* Reset Coupon By Code end */


/* Remove Coupon By Plan */
export const removeCouponByPlan = (couponCode) => {
	return (dispatch, getState) => {
		const {couponYearly2, couponMonthly, coupon, couponByCode} = getState().home;
		if(couponYearly2?.code === couponCode) {
			dispatch(getCouponYearly2Success({}));
		} else if(coupon?.code === couponCode) {
			dispatch(getCouponSuccess({}));
		} else if(couponMonthly?.code === couponCode) {
			dispatch(getCouponMonthlySuccess({}));
		} else if(couponByCode?.code === couponCode) {
			dispatch(resetCoupon());
		}
	};
};
/* Remove Coupon By Plan end */

/* Get Servers Details start */
const updateServersDetails = serversDetails => {
	return {
		type: GET_SERVERS_DETAILS,
		serversDetails
	};
};

export const getServersDetails = () => {
	return (dispatch) => {
		API.getServersDetails()
			.then(response => {
				const serversDetails = response.data || {};
				dispatch(updateServersDetails(serversDetails));
			})
			.catch(() => {
				dispatch(updateServersDetails({}));
			});
	};
};
/* Get Servers Details end */

/* Verify Email start */
export const verifyEmail = data => {
	return () => {
		return API.verifyEmail(data)
			.then(response => {
				return response.data;
			})
			.catch( (err) => {
				return err.response && err.response.data || {};
			});
	};
};
/* Verify Email end */

/* iDeal Bank List start */
const updateIDealBankList = iDealBankList => {
	return {
		type: GET_IDEAL_BANK_LIST,
		iDealBankList
	};
};

export const getIDealBankList = () => {
	return (dispatch) => {
		API.getIDealBankList()
			.then(response => {
				const iDealBankList = response.data || [];
				dispatch(updateIDealBankList(iDealBankList));
			})
			.catch(() => {
				dispatch(updateIDealBankList([]));
			});
	};
};
/* iDeal Bank List end */

/* Save Language start */
export const saveLanguage = data => {
	return () => {
		return API.saveLanguage(data)
			.then(response => {
				return response.data;
			})
			.catch( (err) => {
				return err.response;
			});
	};
};
/* Save Language end */

/* load unconditionally recaptcha key */
const loadUnconditionallyCaptchaKey = () => {
	return {
		type: LOAD_UNCONDITIONALLY_RECAPTCHA
	};
};
/* load unconditionally recaptcha key end */

/* get recaptcha key */
const freeRecaptchaChecked = () => {
	return {
		type: CHECK_FREE_RECAPTCHA
	};
};

export const getRecaptchaKey = (dataToSend, loadUnconditionally) => {
	return async (dispatch) => {
		try {
			const response = await (loadUnconditionally ? API.getUnconditionallyRecaptchaKey() : API.getRecaptchaKey(dataToSend));
			dispatch(freeRecaptchaChecked());
			if(loadUnconditionally && !response?.data?.data?.recaptcha_key) {
				dispatch(createFreemiumFail());
				utils.handleError('cf', new Error('No Unconditionally Recaptcha  Key'));
			}
			return response?.data;
		} catch (err) {
			dispatch(freeRecaptchaChecked());
			if(loadUnconditionally) {
				dispatch(createFreemiumFail());
			}
			return err?.response?.data;
		}
	};
};
/* get recaptcha key */

/* update queryParams */
export const updateQueryParams= queryParams => {
	return {
		type: UPDATE_QUERY_PARAMS,
		queryParams
	};
};
/* update queryParams end */

/* update Sentry Props */
export const updateSentryProps = sentryProps => {
	return {
		type: UPDATE_SENTRY_PROPS,
		sentryProps
	};
};
/* update Sentry Props end */
